import Bugsnag from '@bugsnag/js'
import Vue from 'vue'

import { processDateString } from '@/javascript/lib/validate_date/process-date-string'
import api from '@/javascript/vuejs/services/api'
import {
	getCertifications,
	getCourses,
	postEditCourse,
} from '@/javascript/vuejs/services/courses'

import {
	getDefaultCourse,
	getDefaultHistoricalCourse,
	getDefaultWaiveClass,
	getDefaultWaiveData,
} from '../helpers/_constants'
import type { ActionableCourse, ComplianceTypes } from '../services/courses/interfaces'
import type { CourseStatusActions, HistoricalCourse } from '../services/goals/interfaces'
import type {
	Certification,
	CourseAction,
	CourseData,
	MemberTrainingCourse,
	TrainingMode,
	TrainingTab,
	WaiveData,
	WaiveTypes,
} from '../services/memberTraining/interfaces'
import { getAgencyId } from '../utils/getAgencyId'
import AdministratorStore from './AdministratorStore'
import GlobalStore from './GlobalStore'
import MembersStore from './MembersStore'

const state = Vue.observable({
	action: '' as CourseAction,
	activeTab: 'class library' as TrainingTab,
	canInputBundleName: false,
	canInputDueDate: false,
	certificationBeingRenamed: null as Certification | null,
	certifications: [] as Certification[],
	combinedCourses: [] as MemberTrainingCourse[],
	complianceTypes: {
		annual: '',
		initial: '',
		none: '',
	} as ComplianceTypes,
	courseStatusId: null as string | null,
	courses: [] as MemberTrainingCourse[],
	currentCourseData: {
		description: '',
		length_in_minutes: undefined,
		name: '',
		training: { organization: '' },
	} as CourseData,
	currentStep: 0,
	editCourse: getDefaultCourse(),
	hasSelectedCourses: false,
	historicalCourse: getDefaultHistoricalCourse(),
	isAdditional: false,
	isBulkEnrolling: false,
	isCertifications: false,
	isCertificationsEnabled: false,
	isCoursesLoading: false,
	isEditingExternalCourse: false,
	isProcessing: false,
	isWaivingCourse: false,
	memberIds: [] as number[],
	mode: '' as TrainingMode,
	onEnrollmentConfirmation: false,
	selectedCertificationIds: [] as number[],
	selectedCertifications: [] as Certification[],
	selectedCourseIds: [] as number[],
	selectedCourses: [] as MemberTrainingCourse[],
	selectedWaiveCourseId: null as number | null,
	trainingModalHeading: '',
	validCourseForm: false,
	validTrainingCourseForm: false,
	waiveClass: getDefaultWaiveClass(),
	waiveData: getDefaultWaiveData(),
	waiveTypes: {
		agency: 'none',
		careacademy: 'none',
	} as WaiveTypes,
})

const store = {
	// Getters and Setters
	get action(): CourseAction {
		return state.action
	},
	set action(value) {
		state.action = value
	},

	get activeTab(): TrainingTab {
		return state.activeTab
	},
	set activeTab(value) {
		state.activeTab = value
	},

	get canInputBundleName(): boolean {
		return state.canInputBundleName
	},
	set canInputBundleName(value) {
		state.canInputBundleName = value
	},

	get canInputDueDate(): boolean {
		return state.canInputDueDate
	},
	set canInputDueDate(value) {
		state.canInputDueDate = value
	},

	get certificationBeingRenamed(): Certification | null {
		return state.certificationBeingRenamed
	},
	set certificationBeingRenamed(value) {
		state.certificationBeingRenamed = value
	},

	get certifications(): Certification[] {
		return state.certifications
	},
	set certifications(value) {
		state.certifications = value
	},

	get combinedCourses(): MemberTrainingCourse[] {
		return state.combinedCourses
	},
	set combinedCourses(value) {
		state.combinedCourses = value
	},

	get complianceTypes(): ComplianceTypes {
		return state.complianceTypes
	},
	set complianceTypes(value) {
		state.complianceTypes = value
	},

	get courseStatusId(): string | null {
		return state.courseStatusId
	},
	set courseStatusId(value) {
		state.courseStatusId = value
	},

	get courses(): MemberTrainingCourse[] {
		return state.courses
	},
	set courses(value) {
		switch (state.action) {
			case 'add_historical':
			case 'waive_class':
			case 'waive_external_class':
				state.courses = value.filter(course => 'is_external' in course && course.is_external)
				break

			default:
				state.courses = value
				break
		}
	},

	get currentCourseData(): CourseData {
		return state.currentCourseData
	},
	set currentCourseData(value) {
		state.currentCourseData = value
	},

	get currentStep(): number {
		return state.currentStep
	},
	set currentStep(value) {
		state.currentStep = value
	},

	get editCourse(): MemberTrainingCourse {
		return state.editCourse
	},
	set editCourse(value) {
		state.editCourse = this.modifiedCourseData(value)
	},

	get hasSelectedCourses(): boolean {
		return state.selectedCourseIds.length > 0 || state.selectedCertificationIds.length > 0
	},

	get historicalCourse(): HistoricalCourse {
		return state.historicalCourse
	},
	set historicalCourse(value) {
		state.historicalCourse = value
	},

	get isAdditional(): boolean {
		return state.isAdditional
	},
	set isAdditional(value) {
		state.isAdditional = value
	},

	get isBulkEnrolling(): boolean {
		return state.isBulkEnrolling
	},
	set isBulkEnrolling(value) {
		state.isBulkEnrolling = value
	},

	get isCertifications(): boolean {
		return state.isCertifications
	},
	set isCertifications(value) {
		state.isCertifications = value
	},

	get isCertificationsEnabled(): boolean {
		return state.isCertificationsEnabled
	},
	set isCertificationsEnabled(value) {
		state.isCertificationsEnabled = value
	},

	get isCoursesLoading(): boolean {
		return state.isCoursesLoading
	},
	set isCoursesLoading(value) {
		state.isCoursesLoading = value
	},

	get isEditingExternalCourse(): boolean {
		return state.isEditingExternalCourse
	},
	set isEditingExternalCourse(value) {
		state.isEditingExternalCourse = value
	},

	get isProcessing(): boolean {
		return state.isProcessing
	},
	set isProcessing(value) {
		state.isProcessing = value
	},

	get isWaivingCourse(): boolean {
		return state.action === 'waive_class' ||
			state.action === 'edit_waived_class' ||
			state.action === 'waive_external_class' ||
			state.action === 'add_historical'
	},

	get memberIds(): number[] {
		return state.memberIds
	},
	set memberIds(value) {
		state.memberIds = value
	},

	get mode(): TrainingMode {
		return state.mode
	},
	set mode(value) {
		state.mode = value
	},

	get onEnrollmentConfirmation(): boolean {
		return state.onEnrollmentConfirmation
	},
	set onEnrollmentConfirmation(value) {
		state.onEnrollmentConfirmation = value
	},

	get selectedCertificationIds(): number[] {
		return state.selectedCertificationIds
	},
	set selectedCertificationIds(value) {
		state.selectedCertificationIds = value
	},

	get selectedCertifications(): Certification[] {
		return state.certifications?.filter((course: Certification) =>
			state.selectedCertificationIds.includes(course.id)
		) || []
	},

	get selectedCourseIds(): number[] {
		return state.selectedCourseIds
	},
	set selectedCourseIds(value) {
		state.selectedCourseIds = value
	},

	get selectedCourses(): MemberTrainingCourse[] {
		const coursesFromLibrary = state.courses.filter((course: Certification | MemberTrainingCourse) => {
			return course.id ? state.selectedCourseIds.includes(course.id) : false
		})

		return [...state.combinedCourses, ...coursesFromLibrary]
	},

	get selectedWaiveCourseId(): number | null {
		return state.selectedWaiveCourseId
	},
	set selectedWaiveCourseId(value) {
		state.selectedWaiveCourseId = value
	},

	get trainingModalHeading(): string {
		switch (state.action) {
			case 'add_historical':
				return 'Add completed class'

			case 'edit_historical':
				return 'Edit completed class'

			case 'edit_waived_class':
				return 'Edit waived class'

			case 'enroll':
				return 'Add training'

			case 'waive_class':
				return 'Waive class'

			case 'waive_external_class':
				return 'Waive external class'

			default:
				return AdministratorStore.modalHeading
		}
	},

	get validCourseForm(): boolean {
		return state.validCourseForm
	},
	set validCourseForm(value) {
		state.validCourseForm = value
	},

	get validTrainingCourseForm(): boolean {
		return state.validTrainingCourseForm
	},
	set validTrainingCourseForm(value) {
		state.validTrainingCourseForm = value
	},

	get waiveClass(): ActionableCourse {
		return state.waiveClass
	},
	set waiveClass(value) {
		state.waiveClass = value
	},

	get waiveData(): WaiveData {
		return state.waiveData
	},
	set waiveData(value) {
		state.waiveData = value
	},

	get waiveTypes(): WaiveTypes {
		return state.waiveTypes
	},
	set waiveTypes(value) {
		state.waiveTypes = value
	},

	// Actions
	clearModal(): void {
		GlobalStore.resetCAModal()
		this.resetEditCourse()
		this.goToStep(0)
		this.deselectAllCourses()
		this.setActiveTab('class library')

		state.combinedCourses = []
		state.onEnrollmentConfirmation = false
	},

	courseAlreadySelected(course: MemberTrainingCourse): boolean {
		return state.combinedCourses.indexOf(course) >= 0
	},

	createNewCourse(): void {
		const newCourse = {
			...state.editCourse,
			courseStatusActions: {
				renaming: false,
				selected: false,
				type: 'courses',
			} as CourseStatusActions,
			isModifiable: true,
			is_external: true,
		}

		state.combinedCourses.push(newCourse)
		state.editCourse = newCourse
		state.onEnrollmentConfirmation = true
	},

	deselectAllCourses(): void {
		state.certifications?.map(certification => certification.courseStatusActions.selected = false)
		state.courses?.map(course => course.courseStatusActions.selected = false)
		state.selectedCertificationIds = []
		state.selectedCourseIds = []
	},

	deselectSingleCourse({ courseId }: { courseId?: number }, key: CourseKey): void {
		if (!courseId) {
			return
		}

		const index = state[key].indexOf(courseId)

		if (index >= 0) {
			this.isCourseSelected({ courseId }, key)
			state[key].splice(index, 1)
		}

		const curriculum =
			key === 'selectedCourseIds'
				? state.selectedCourseIds
				: state.selectedCertificationIds
		const selectedIndex = curriculum.findIndex(id => id === courseId)

		if (selectedIndex >= 0) {
			curriculum.splice(selectedIndex, 1)
		}
	},

	goToStep(step: number): void {
		this.currentStep = step < 0 ? 0 : step // prevent currentStep from going to negative numbers (edge case)
	},

	isCourseSelected({ courseId }: { courseId: number }, key: CourseKey): boolean {
		if (key === 'selectedCourseIds') {
			const course = state.courses.find(course => course.id === courseId)

			return course && course.courseStatusActions ?
				course.courseStatusActions.selected = !course.courseStatusActions.selected : false

		} else {
			const course = state.certifications.find(course => course.id === courseId)

			return course && course.courseStatusActions ?
				course.courseStatusActions.selected = !course.courseStatusActions.selected : false
		}
	},

	isEditingCourse(value: boolean): void {
		this.isEditingExternalCourse = value

		if (!value && state.action === 'enroll') {
			this.resetEditCourse()
		}
	},

	async loadCertifications(): Promise<void> {
		if (this.isCertificationsEnabled) {
			try {
				const response = await getCertifications(api, getAgencyId())

				state.certifications = response.careAcademySpecializedCertifications.map(
					course => ({
						...course,
						courseStatusActions: {
							renaming: false,
							selected: false,
							type: 'certifications',
						},
					})
				)
			} catch (error: any) {
				console.error(error)
			}
		}
	},

	async loadCourses(next: (courses: MemberTrainingCourse[]) => void): Promise<void> {
		try {
			const response: MemberTrainingCourse[] = await getCourses(
				api,
				{
					agencyId: getAgencyId(),
					memberId: state.memberIds?.length === 1 ?
						state.memberIds[0] : null,
				})

			next(response)
		} catch (error: any) {
			console.error(error)
		} finally {
			state.isCoursesLoading = false
		}
	},

	modifiedCourseData(course: MemberTrainingCourse): MemberTrainingCourse {
		let courseTrainingCompletionDate: string | undefined

		if (course?.training?.completionDate) {
			const dateValue = processDateString({
				dateString: course.training?.completionDate,
				isRequired: false,
				maxDateString: '',
				minDateString: '',
			})

			courseTrainingCompletionDate = dateValue.formattedDate
		}

		return {
			...course,
			add_to_library: course.add_to_library || false,
			courseStatusActions: {
				renaming: false,
				selected: course.courseStatusActions?.selected || false,
				type: 'courses',
			},
			course_status_id: state.courseStatusId ? parseInt(state.courseStatusId) : null,
			description: course.description,
			id: course.id,
			is_external: course.is_external,
			length_in_minutes: course.length_in_minutes,
			name: course.name,
			training: {
				completionDate: courseTrainingCompletionDate ?? '',
				grade: course.training?.grade,
				location: course.training?.location ?? '',
				organization:
					course.training?.organization ||
					course.training_organization ||
					GlobalStore.agency.name,
				trainer: course.training?.trainer || '',
			},
			training_organization:
				course.training?.organization ||
				course.training_organization ||
				GlobalStore.agency.name,
			type: course.type,
		}
	},

	next(): void {
		this.currentStep += 1
	},

	previous(): void {
		this.currentStep = this.currentStep < 0 ? 0 : this.currentStep - 1 // prevent currentStep from going to negative numbers (edge case)
	},

	removeCourseFromEnrollment({
		courseId, key, name,
	}: { courseId: number | null, key: CourseKey, name: string }): void {
		if (courseId) {
			this.deselectSingleCourse({ courseId }, key)
		}

		if (state.activeTab !== 'new class') {
			this.removeFromCreatedCourses(name)
		}
		this.resetEditCourse()

		if (!this.hasSelectedCourses) {
			this.goToStep(0)
			state.currentStep = 0
		}
	},

	removeFromCreatedCourses(name: string): void {
		const courseIndex = state.combinedCourses.findIndex(course => course.name === name)

		if (courseIndex >= 0) {
			state.combinedCourses.splice(courseIndex, 1)
		}
	},

	resetCertificationBeingRenamed(course: Certification): void {
		const certification = state.certifications.find(x => x.id === course.id)

		if (certification) {
			certification.courseStatusActions.renaming = false
			state.certificationBeingRenamed = null
		}
	},

	resetData(): void {
		this.clearModal()
		this.unloadGoal()
		state.action = ''
		state.courseStatusId = null
		state.currentCourseData = {
			description: '',
			length_in_minutes: undefined,
			name: '',
			training: { organization: '' },
		}

		state.editCourse = getDefaultCourse()
		state.historicalCourse = getDefaultHistoricalCourse()
		state.isBulkEnrolling = false
		state.mode = ''
		state.isEditingExternalCourse = false
		state.waiveClass = getDefaultWaiveClass()
		state.waiveData = getDefaultWaiveData()

		GlobalStore.clearSelectedValues()
		GlobalStore.resetCAModal()
	},

	resetEditCourse(): void {
		state.editCourse = getDefaultCourse()
		state.isEditingExternalCourse = false
	},

	restoreCourseData(): void {
		state.editCourse.description = this.currentCourseData.description
		state.editCourse.length_in_minutes = this.currentCourseData.length_in_minutes
		state.editCourse.name = this.currentCourseData.name
		state.editCourse.training.organization = this.currentCourseData.training.organization
	},

	saveCurrentCourseData(): void {
		state.currentCourseData.description = this.editCourse.description
		state.currentCourseData.length_in_minutes = this.editCourse.length_in_minutes
		state.currentCourseData.name = this.editCourse.name
		state.currentCourseData.training.organization = this.editCourse.training.organization
	},

	selectMultipleCourses({ courseId }: { courseId: number }, key: CourseKey): void {
		state[key].push(courseId)
		this.isCourseSelected({ courseId }, key)
	},

	selectSingleCourse({ courseId, type }: { courseId: number, type?: 'checkbox' | 'radio' }, key: CourseKey = 'selectedCourseIds'): void {
		state[key] = [courseId]
		state.selectedWaiveCourseId = courseId

		if (type === 'radio') {
			state.courses.map(course => {
				if (course.id !== courseId) {
					course.courseStatusActions.selected = false
				}
			})
		}

		this.isCourseSelected({ courseId }, key)

		const course = state.courses.find(course => course.id === courseId)

		state.editCourse = course && 'description' in course ? course : getDefaultCourse()
	},

	setActiveTab(tabName: TrainingTab): void {
		state.activeTab = tabName
		state.isCertifications = tabName === 'class list'

		this.resetEditCourse()
	},

	setCertificationBeingRenamed(course: Certification): void {
		const certification = state.certifications.find(x => x.id === course.id)

		if (certification && 'classes' in certification) {
			certification.courseStatusActions.renaming = true
			state.certificationBeingRenamed = certification
		}
	},

	setCourses(courses: MemberTrainingCourse[]): void {
		if (!courses.length) {
			return
		}

		state.courses = courses.map(course => {
			return this.modifiedCourseData(course)
		})
	},

	async showAddTrainingModal({
		action,
		canInputBundleName,
		canInputDueDate,
		isAdditional,
		isBulkEnrolling,
		isCoursesLoading,
		memberIds,
	}: {
		action: CourseAction,
		canInputBundleName: boolean,
		canInputDueDate: boolean,
		isAdditional: boolean,
		isBulkEnrolling: boolean,
		isCoursesLoading: boolean,
		memberIds: number[],
	}): Promise<void> {
		this.clearModal()

		if (state.action.startsWith('edit')) {
			this.goToStep(1)
		}

		state.action = action
		state.canInputBundleName = canInputBundleName
		state.canInputDueDate = canInputDueDate
		state.isAdditional = isAdditional
		state.isBulkEnrolling = isBulkEnrolling
		state.isCoursesLoading = isCoursesLoading
		state.memberIds = memberIds

		GlobalStore.emittedEvent = 'add-training'

		try {
			await this.loadCertifications()
			await this.loadCourses(courses => this.setCourses(courses))
		} catch (error: any) {
			console.error(error)
		} finally {
			state.isCoursesLoading = false
		}
	},

	unloadGoal(): void {
		MembersStore.goal = null
		state.memberIds = []
		state.certifications = []
		state.courses = []
	},

	async updateCourse(): Promise<void> {
		if (!state.editCourse) {
			return
		}

		const courseData: CourseData = {
			course_id: state.editCourse.id,
			description: 'description' in state.editCourse ?
				state.editCourse.description : '',
			length_in_minutes: 'length_in_minutes' in state.editCourse ?
				state.editCourse.length_in_minutes : undefined,
			name: state.editCourse.name,
			training: {
				organization:
					'training' in state.editCourse && state.editCourse.training_organization ?
						state.editCourse.training_organization :
						GlobalStore.agency.name,
			},
		}

		try {
			await postEditCourse(api, courseData)

			const course = state.courses.find(course => course.id === courseData.course_id)

			if (course) {
				if ('description' in course) {
					course.description = courseData.description
					course.length_in_minutes = courseData.length_in_minutes
				}

				course.name = courseData.name
			}

			if (state.currentStep === 0) {
				this.goToStep(0)
			}

			this.isEditingCourse(false)
			this.clearModal()

			GlobalStore.onHandleSuccessMessages({ message: `${courseData.name} has been updated.` })
		} catch (error: any) {
			Bugsnag.notify(error)

			if (error.request?.status === 500) {
				GlobalStore.onHandleErrorMessages({
					error:
						`${courseData.name} could not be updated. There was a problem with your request.`,
				})
			} else {
				GlobalStore.onHandleErrorMessages({ error })
			}
		}
	},
}

export default store

export type CourseKey
	= 'selectedCertificationIds'
	| 'selectedCourseIds'
