import Vue from 'vue'

import type {
	AccountNotification,
	CurrentUser,
	Permissions,
	SortDirection,
} from '@/javascript/interfaces'

import { PER_PAGE_DEFAULT } from '../helpers/_constants'
import type {
	LastPrinted,
	Reports,
	Seats,
} from '../services/administratorDashboard/interfaces'
import type { AgencyAdministrator, AgencyAdministratorPaths } from '../services/agencyAdministrators/interfaces'
import api from '../services/api'
import { getMembers, type SortBy } from '../services/members'
import type {
	FilterAndSort,
	Member,
	MemberFilter,
} from '../services/members/interfaces'
import { getAccountNotifications } from '../services/team'
import { getAgencyId } from '../utils/getAgencyId'
import GlobalStore from './GlobalStore'
import MembersStore from './MembersStore'

const state = Vue.observable({
	accountNotifications: [] as AccountNotification[],
	administratorPaths: {
		create: '',
		delete: '',
		list: '',
		update: '',
		verify: '',
	} as AgencyAdministratorPaths,
	annualDueDateSoonInDays: 0,
	canModifyAdmins: false,
	canModifyBilling: false,
	currentUser: null as CurrentUser | null,
	hideSelectAll: false,
	inactiveMembersUrl: '',
	lastPrinted: {
		certificate: '',
		report: '',
	} as LastPrinted,
	loading: false,
	memberSearchInput: '',
	modalHeading: '',
	pagination: {
		currentPage: 1,
		filterBy: '' as MemberFilter,
		filterByQuery: '',
		filterGroupBy: null as number | null,
		perPage: PER_PAGE_DEFAULT,
		sortBy: '' as SortBy,
		sortDirection: 'ascending' as SortDirection,
	} as FilterAndSort,
	paginationTotal: 0 as number,
	permissions: { canModifyAdmins: false } as Permissions,
	printReportUrl: '',
	reports: {} as Reports,
	seats: {
		max: 0,
		used: 0,
	} as Seats,
	selectedAdmin: {
		can_modify_admins: false,
		email: '',
		first_name: '',
		id: null,
		last_name: '',
		phone: '',
		url_for_editing: '',
	} as AgencyAdministrator,
	selectedMembers: [] as Member[],
	showInitialTrainingDueDate: false,
	softwareProviderName: '',
})

const store = {
	get accountNotifications(): AccountNotification[] {
		return state.accountNotifications
	},
	set accountNotifications(value) {
		state.accountNotifications = value
	},

	get administratorPaths(): AgencyAdministratorPaths {
		return state.administratorPaths
	},
	set administratorPaths(value) {
		state.administratorPaths = value
	},

	get annualDueDateSoonInDays(): number {
		return state.annualDueDateSoonInDays
	},
	set annualDueDateSoonInDays(value) {
		state.annualDueDateSoonInDays = value
	},

	get canModifyAdmins(): boolean {
		return state.canModifyAdmins
	},
	set canModifyAdmins(value) {
		state.canModifyAdmins = value
	},

	get canModifyBilling(): boolean {
		return state.canModifyBilling
	},

	set canModifyBilling(value) {
		state.canModifyBilling = value
	},

	get currentUser(): CurrentUser | null {
		return state.currentUser
	},
	set currentUser(value) {
		state.currentUser = value
	},

	get hideSelectAll(): boolean {
		return state.hideSelectAll
	},

	set hideSelectAll(value) {
		state.hideSelectAll = value
	},

	get inactiveMembersUrl(): string {
		return state.inactiveMembersUrl
	},
	set inactiveMembersUrl(value) {
		state.inactiveMembersUrl = value
	},

	get lastPrinted(): LastPrinted {
		return state.lastPrinted
	},
	set lastPrinted(value) {
		state.lastPrinted = value
	},

	get loading(): boolean {
		return state.loading
	},
	set loading(value) {
		state.loading = value
	},

	get memberSearchInput(): string {
		return state.memberSearchInput
	},
	set memberSearchInput(value) {
		state.memberSearchInput = value
	},

	get modalHeading(): string {
		return state.modalHeading
	},
	set modalHeading(value) {
		state.modalHeading = value
	},

	get pagination(): FilterAndSort {
		return state.pagination
	},
	set pagination(value) {
		state.pagination = value
	},

	get permissions(): Permissions {
		return state.permissions
	},
	set permissions(value) {
		state.permissions = value
	},

	get printReportUrl(): string {
		return state.printReportUrl
	},
	set printReportUrl(value) {
		state.printReportUrl = value
	},

	get reports(): Reports {
		return state.reports
	},
	set reports(value) {
		state.reports = value
	},

	get seats(): Seats {
		return state.seats
	},
	set seats(value) {
		state.seats = value
	},

	get selectedAdmin(): AgencyAdministrator {
		return state.selectedAdmin
	},
	set selectedAdmin(value) {
		state.selectedAdmin = value
	},

	get selectedAll(): boolean {
		return state.selectedMembers.length === MembersStore.memberList.length
	},
	set selectedAll(value) {
		if (value) {
			state.selectedMembers = MembersStore.memberList
		} else {
			state.selectedMembers = []
		}
	},

	get selectedMembers(): Member[] {
		return state.selectedMembers
	},
	set selectedMembers(value) {
		state.selectedMembers = value
	},

	get showInitialTrainingDueDate(): boolean {
		return state.showInitialTrainingDueDate
	},
	set showInitialTrainingDueDate(value) {
		state.showInitialTrainingDueDate = value
	},

	get softwareProviderName(): string {
		return state.softwareProviderName
	},
	set softwareProviderName(value) {
		state.softwareProviderName = value
	},

	get paginationTotal(): number {
		return state.paginationTotal
	},
	set paginationTotal(value) {
		state.paginationTotal = value
	},

	// Actions
	clearAdmin(): void {
		state.selectedAdmin = {
			can_modify_admins: false,
			can_modify_billing: false,
			email: '',
			first_name: '',
			id: null,
			last_name: '',
			phone: '',
			url_for_editing: '',
		}

		GlobalStore.resetCAModal()
	},

	getSelectedMemberIds(): number[] {
		return state.selectedMembers.map(({ id }) => id)
	},

	handlePageChange(page: number): void {
		state.pagination.currentPage = page
	},

	resetData(): void {
		state.selectedMembers = [] as Member[]
	},

	async retrieveAccountNotifications(): Promise<void> {
		try {
			if (getAgencyId()) {
				const response = await getAccountNotifications(api, getAgencyId())

				state.accountNotifications = response
			}
		} catch (error: any) {
			GlobalStore.onHandleErrorMessages({ error })
		}
	},

	async retrieveMembers(): Promise<void> {
		try {
			state.loading = true

			if (getAgencyId()) {
				const { pagination } = state
				const result = await getMembers(api, getAgencyId(), {
					filter: pagination.filterBy,
					group_id: pagination.filterGroupBy,
					page: pagination.currentPage,
					per_page: pagination.perPage,
					query: pagination.filterByQuery,
					sort_by: pagination.sortBy,
					sort_direction: pagination.sortDirection,
				})

				MembersStore.memberList = result['data']
				state.paginationTotal = result['pagination']['total']
			}
		} catch (error) {
			console.error(error)
		} finally {
			state.loading = false
		}
	},

	isSelectedMember(value: Member): boolean {
		return state.selectedMembers.includes(value)
	},

	updateSelectedMembers(value: Member, isSelected?: boolean): void {
		const selectedIndex = state.selectedMembers.findIndex(member => member.id === value.id)

		if (isSelected) {
			// Add the member to the selectedMembers array
			state.selectedMembers = [...state.selectedMembers, value]
		} else {
			if (selectedIndex !== -1) {
				// Remove the member from the selectedMembers array
				state.selectedMembers = state.selectedMembers.filter(member => member.id !== value.id)
			}
		}
	},
}

export default store
