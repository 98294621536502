import type { CAAction, CAFormOption } from '@careacademy/ca-design-system'
import Vue from 'vue'

import type { CAMember } from '@/javascript/interfaces'
import type { Pagination, PrebuiltTrack } from '@/javascript/interfaces'
import {
	type ApiTrackCreateEditResponse,
	postTrackCreate,
	postTrackDuplicate,
} from '@/javascript/vuejs/services/curriculums'
import {
	deleteTrainingGroup,
	getAgencyTeam,
	getTeamMember,
	getTeamMembers,
	postAddTrainingGroup,
	putUpdateTrainingGroup,
} from '@/javascript/vuejs/services/team'

import { DEFAULT_SELECTED_CURRICULUM_NAME, TEAM_MEMBERS_PER_PAGE } from '../helpers/_constants'
import api from '../services/api'
import type { CurriculumTrack } from '../services/courses/interfaces'
import type { GroupMember } from '../services/groups/interfaces'
import type { Member, MemberContact } from '../services/members/interfaces'
import type {
	AgencyGroup,
	AgencyTeam,
	DueDateSettings,
	NewCurriculum,
	TrainingOption,
} from '../services/team/interfaces'
import CurriculumsStore from '../stores/CurriculumsStore'
import GlobalStore from '../stores/GlobalStore'
import GroupsStore from '../stores/GroupsStore'
import MembersStore from '../stores/MembersStore'
import { getAgencyId } from '../utils/getAgencyId'
import { replaceText } from '../utils/stringUtils'

export const state = Vue.observable({
	agencyTeam: {} as AgencyTeam,
	agencyTeamMembers: [] as Member[],
	checkedInitialTraining: false,
	complianceDueDate: '',
	copiedCurriculumId: '' as string,
	curriculumTextDuplicate: '',
	curriculumTextNoMatch: '',
	curriculumTextPrebuilt: '',
	daysBeforeDueDate: 10,
	defaultCurriculum: null as CurriculumTrack | null,
	defaultGroup: null as AgencyGroup | null,
	defaultNewCurriculum: {
		curriculum: {
			id: '0',
			name: DEFAULT_SELECTED_CURRICULUM_NAME,
		},
		name: '',
		type: 'duplicate' as TrainingOption,
	} as NewCurriculum,
	dueDateSettings: {} as DueDateSettings,
	isChangingCurriculum: false,
	isDisabledAddTeamMember: false,
	isDisabledUpdateTrainingGroup: false,
	isLoadingCurriculums: false,
	isLoadingDueDateSettings: false,
	isLoadingGroups: false,
	isLoadingMembers: false,
	isProcessing: false,
	listOfTrainingGroupIds: 0,
	membersPaginationTotal: 0 as number,
	membersToMessage: [] as MemberContact[],
	newCurriculum: {
		curriculum: {
			id: '',
			name: DEFAULT_SELECTED_CURRICULUM_NAME,
		},
		name: '',
		type: 'blank' as TrainingOption,
	} as NewCurriculum,
	newGroupName: '',
	pagination: {
		currentPage: 1,
		perPage: TEAM_MEMBERS_PER_PAGE,
	} as Pagination,
	params: {} as Record<string, string>,
	prebuiltTracks: [] as PrebuiltTrack[],
	radioGroupDueDateIsSingle: false,
	radioRelativeDueDate: false,
	radioSingleDueDate: false,
	selectedCurriculum: null as CurriculumTrack | null,
	selectedCurriculumId: null as number | null,
	selectedGroup: undefined as AgencyGroup | undefined,
	selectedGroupId: null as number | null,
	selectedGroupMembers: [] as GroupMember[],
	selectedMember: undefined as Member | undefined,
	selectedMemberId: null as number | null,
	selectedMemberIds: [] as string[],
	showCreateNewCurriculumSlideover: false,
	showDeleteCurriculumTrackModal: false,
	showSelectCurriculumSlideover: false,
	slideoverCurriculumId: '',
	slideoverCurriculums: [] as CurriculumTrack[] | PrebuiltTrack[],
	slideoverPrebuiltCurriculums: [] as PrebuiltTrack[],
	slideoverRadioButtonId: 'curriculum-select-',
	slideoverTrainingCurriculums: [] as CAFormOption[],
	trainingCurriculums: [] as CurriculumTrack[],
	trainingGroups: [] as AgencyGroup[],
})

const store = {
	get agencyTeam(): AgencyTeam {
		return state.agencyTeam
	},
	set agencyTeam(value) {
		state.agencyTeam = value
	},

	get agencyTeamMembers(): Member[] {
		return state.agencyTeamMembers.filter(member => member.status === 'active')
	},
	set agencyTeamMembers(value) {
		state.agencyTeamMembers = value
	},

	get checkedInitialTraining(): boolean {
		return state.radioSingleDueDate && !state.radioRelativeDueDate || state.radioGroupDueDateIsSingle
	},

	get complianceDueDate(): string {
		return state.complianceDueDate
	},
	set complianceDueDate(value) {
		state.complianceDueDate = value
	},

	get copiedCurriculumId(): string {
		return state.copiedCurriculumId
	},
	set copiedCurriculumId(value) {
		state.copiedCurriculumId = value
	},

	get curriculumTextDuplicate(): string {
		return `Select one of your existing curricula or create a new one. Team members in <span class="text-brand-blue-400">${!state.selectedGroup ? '[training group name]' : state.selectedGroup?.name}</span> will be automatically assigned to this curriculum.`
	},

	get curriculumTextNoMatch(): string {
		return 'There are no curricula to match your search.'
	},

	get curriculumTextPrebuilt(): string {
		return `Select one of CareAcademy's pre-built compliance curricula.
		You can customize this curriculum's training at any time after creating the training group.`
	},

	get daysBeforeDueDate(): number {
		return state.daysBeforeDueDate
	},
	set daysBeforeDueDate(value) {
		state.daysBeforeDueDate = value
	},

	get defaultCurriculum(): CurriculumTrack | null {
		return state.defaultCurriculum ?? state.trainingCurriculums.find(curriculum => curriculum.is_default) ?? null
	},
	set defaultCurriculum(value) {
		state.defaultCurriculum = value
	},

	get defaultGroup(): AgencyGroup | null {
		return state.defaultGroup ?? state.trainingGroups.find(group => group.is_default) ?? null
	},
	set defaultGroup(value) {
		state.defaultGroup = value
	},

	get defaultNewCurriculum(): NewCurriculum {
		return state.defaultNewCurriculum
	},

	get dueDateSettings(): DueDateSettings {
		return state.dueDateSettings
	},
	set dueDateSettings(value) {
		state.dueDateSettings = value
	},

	get isChangingCurriculum(): boolean {
		return GlobalStore.emittedEvent === 'change-group-curriculum'
	},

	get isDisabledAddTeamMember(): boolean {
		return state.isDisabledAddTeamMember
	},
	set isDisabledAddTeamMember(value) {
		state.isDisabledAddTeamMember = value
	},

	get isDisabledUpdateTrainingGroup(): boolean {
		return state.isDisabledUpdateTrainingGroup
	},
	set isDisabledUpdateTrainingGroup(value) {
		state.isDisabledUpdateTrainingGroup = value
	},

	get isLoadingCurriculums(): boolean {
		return state.isLoadingCurriculums
	},
	set isLoadingCurriculums(value) {
		state.isLoadingCurriculums = value
	},

	get isLoadingDueDateSettings(): boolean {
		return state.isLoadingDueDateSettings
	},
	set isLoadingDueDateSettings(value) {
		state.isLoadingDueDateSettings = value
	},

	get isLoadingGroups(): boolean {
		return state.isLoadingGroups
	},
	set isLoadingGroups(value) {
		state.isLoadingGroups = value
	},

	get isLoadingMembers(): boolean {
		return state.isLoadingMembers
	},
	set isLoadingMembers(value) {
		state.isLoadingMembers = value
	},

	get isProcessing(): boolean {
		return state.isProcessing
	},
	set isProcessing(value) {
		state.isProcessing = value
	},

	get listOfTrainingGroupIds(): number[] {
		return state.trainingGroups.map(group => group.id)
	},

	get membersPaginationTotal(): number {
		return state.membersPaginationTotal
	},
	set membersPaginationTotal(value) {
		state.membersPaginationTotal = value
	},

	get membersToMessage(): MemberContact[] {
		switch (GlobalStore.emittedEvent) {
			case 'send-bulk-message':
				return GlobalStore.selectedCATableMembers.map((member: CAMember) => ({
					id: member.id,
					name: member.name,
					phone_number: member.phone ?? '',
				}))

			case 'send-group-message':
				return state.selectedGroupMembers.map(member => ({
					id: member.id,
					name: member.name,
					phone_number: member.phone ?? '',
				}))

			case 'send-member-message':
				return [
					{
						id: state.selectedMember?.id ?? 0,
						name: `${state.selectedMember?.first_name} ${state.selectedMember?.last_name}`,
						phone_number: state.selectedMember?.phone_number ?? '',
					},
				]

			default:
				return []
		}
	},

	get newCurriculum(): NewCurriculum {
		return state.newCurriculum
	},
	set newCurriculum(value) {
		state.newCurriculum = value
	},

	get newGroupName(): string {
		return state.newGroupName
	},
	set newGroupName(value) {
		state.newGroupName = value
	},

	get pagination(): Pagination {
		return state.pagination
	},
	set pagination(value) {
		state.pagination = value
	},

	get prebuiltTracks(): PrebuiltTrack[] {
		return state.prebuiltTracks
	},
	set prebuiltTracks(value) {
		state.prebuiltTracks = value
	},

	get radioGroupDueDateIsSingle(): boolean {
		return state.radioGroupDueDateIsSingle
	},
	set radioGroupDueDateIsSingle(value) {
		state.radioGroupDueDateIsSingle = value
	},

	get radioRelativeDueDate(): boolean {
		return state.radioRelativeDueDate
	},
	set radioRelativeDueDate(value) {
		state.radioRelativeDueDate = value
	},

	get radioSingleDueDate(): boolean {
		return state.radioSingleDueDate
	},
	set radioSingleDueDate(value) {
		state.radioSingleDueDate = value
	},

	get selectedCurriculum(): CurriculumTrack | null {
		return state.trainingCurriculums.find(curriculum => curriculum.id === state.selectedCurriculumId) ?? null
	},

	get selectedCurriculumId(): number | null {
		return state.selectedCurriculumId
	},
	set selectedCurriculumId(value) {
		state.selectedCurriculumId = value
	},

	get selectedGroup(): AgencyGroup | undefined {
		return state.trainingGroups.find(group => group.id === state.selectedGroupId)
	},

	get selectedGroupId(): number | null {
		return GroupsStore.selectedGroupId ?? state.selectedGroupId ?? null
	},
	set selectedGroupId(value) {
		state.selectedGroupId = value
	},

	get selectedGroupMembers(): GroupMember[] {
		return state.selectedGroupMembers
	},
	set selectedGroupMembers(value) {
		state.selectedGroupMembers = value
	},

	get selectedMember(): Member | undefined {
		return state.selectedMember
	},
	set selectedMember(value) {
		state.selectedMember = value
	},

	get selectedMemberId(): number | null {
		return state.selectedMemberId
	},
	set selectedMemberId(value) {
		state.selectedMemberId = value
	},

	get selectedMemberIds(): string[] {
		return state.selectedMemberIds
	},
	set selectedMemberIds(value) {
		state.selectedMemberIds = value
	},

	get showCreateNewCurriculumSlideover(): boolean {
		return state.showCreateNewCurriculumSlideover
	},
	set showCreateNewCurriculumSlideover(value) {
		state.showCreateNewCurriculumSlideover = value
	},

	get showDeleteCurriculumTrackModal(): boolean {
		return state.showDeleteCurriculumTrackModal
	},
	set showDeleteCurriculumTrackModal(value) {
		state.showDeleteCurriculumTrackModal = value
	},

	get slideoverCurriculumId(): string {
		const id = `${replaceText(this.slideoverRadioButtonId, '', state.newCurriculum.curriculum.id)}`

		return id ?? `${replaceText(this.slideoverRadioButtonId, '', `${state.selectedCurriculumId}`)}`
	},

	get showSelectCurriculumSlideover(): boolean {
		return state.showSelectCurriculumSlideover
	},
	set showSelectCurriculumSlideover(value) {
		state.showSelectCurriculumSlideover = value
	},

	get slideoverCurriculums(): CurriculumTrack[] | PrebuiltTrack[] {
		return state.slideoverCurriculums
	},
	set slideoverCurriculums(value) {
		state.slideoverCurriculums = value
	},

	get slideoverPrebuiltCurriculums(): CAFormOption[] {
		if (!state.prebuiltTracks) {
			return []
		}

		return state.prebuiltTracks.map((curriculum: CurriculumTrack | PrebuiltTrack) => ({
			id: `${this.slideoverRadioButtonId}${curriculum.id}`,
			label: `${curriculum.name}`,
			tags: !!this.getGroupsUsingTrainingCurriculum(curriculum.id).length ?
				this.getGroupsUsingTrainingCurriculum(curriculum.id).map((group: AgencyGroup) => group.name)
				: [],
			tagsLabel: 'used by:',
		}))
	},

	get slideoverRadioButtonId(): string {
		// NOTE: `curriculum-change-` or `curriculum-select-` is added to the id to avoid conflicts with the radio group
		return this.isChangingCurriculum && !this.showSelectCurriculumSlideover ? 'curriculum-change-' : 'curriculum-select-'
	},

	get slideoverTrainingCurriculums(): CAFormOption[] {
		if (!state.slideoverCurriculums) {
			return []
		}

		return state.slideoverCurriculums.map((curriculum: CurriculumTrack | PrebuiltTrack) => ({
			actions: this.isChangingCurriculum && !this.showSelectCurriculumSlideover ? [
				{
					color: 'danger',
					dataTest: 'action-delete-curriculum',
					icon: 'SVGTrash',
					id: `delete-curriculum-${curriculum.id}`,
					name: 'delete',
					tooltip: `Delete ${curriculum.name}`,
				},
			] as CAAction[] : undefined,
			id: `${this.slideoverRadioButtonId}${curriculum.id}`,
			label: `${curriculum.name}`,
			tags: !!this.getGroupsUsingTrainingCurriculum(curriculum.id).length ?
				this.getGroupsUsingTrainingCurriculum(curriculum.id).map((group: AgencyGroup) => group.name)
				: [],
			tagsLabel: 'used by:',
		}))
	},

	get trainingCurriculums(): CurriculumTrack[] {
		return state.trainingCurriculums
	},
	set trainingCurriculums(value) {
		state.trainingCurriculums = value
	},

	get trainingGroups(): AgencyGroup[] {
		return state.trainingGroups
	},
	set trainingGroups(value) {
		state.trainingGroups = value
	},

	// Actions
	async agencyDueDateSettings(): Promise<void> {
		state.isLoadingDueDateSettings = true

		try {
			const response = await getAgencyTeam(api, getAgencyId())

			state.dueDateSettings = response.agency.due_dates
			GlobalStore.agencyBackOffice = {
				backOfficeIntegrationEnabled: response.agency.back_office_integration_enabled,
				backOfficeIntegrationProviderName: response.agency.back_office_integration_provider_name,
				marketingProductName: response.agency.marketing_product_name,
			}
		} catch (error: any) {
			GlobalStore.onHandleErrorMessages({ error })
		} finally {
			state.isLoadingDueDateSettings = false
		}
	},

	async deleteGroup(): Promise<void> {
		try {
			await deleteTrainingGroup(api, getAgencyId(), state.selectedGroupId!)
			await this.getAgencyTeamGroups()

			GlobalStore.onHandleSuccessMessages({ message: 'Group deleted successfully.' })

			GlobalStore.clearSelectedValues()
			GlobalStore.resetCAModal()
		} catch (error: any) {
			GlobalStore.onHandleErrorMessages({ error })
		}
	},

	async getAgencyTeamMembers({
		groupId = null,
		page = 1,
		perPage = TEAM_MEMBERS_PER_PAGE,
		query = '',
		sortDirection = 'asc',
		sortBy = 'name',
		statuses = ['active'],
		roles = [],
		trainingGroups = [],
	}: {
		groupId: number | null,
		page?: number,
		perPage?: number,
		query?: string,
		roles?: string[],
		sortBy?: string,
		sortDirection?: string,
		statuses?: string[],
		trainingGroups?: string[],
	}): Promise<void> {
		state.isLoadingMembers = true

		try {
			const response = await getTeamMembers(api, {
				agency_id: getAgencyId(),
				group_ids: groupId ? [`${groupId}`, ...trainingGroups] : trainingGroups,
				page,
				perPage,
				query,
				roles,
				sort_by: sortBy,
				sort_direction: sortDirection,
				statuses,
			})

			state.agencyTeamMembers = response.data

			if (groupId) {
				state.selectedGroupMembers = response.data.map((member: Member) => ({
					email: member.email_address ?? '',
					id: member.id,
					name: member.name ?? '',
					phone: member.phone_number ?? '',
					role: member.role,
					stringToSearch: member.name ?? '',
				}))
			}

			state.pagination = {
				currentPage: response['pagination']['current_page'],
				perPage: response['pagination']['per_page'],
			}
			state.membersPaginationTotal = response['pagination']['total']
		} catch (error: any) {
			GlobalStore.onHandleErrorMessages({ error })
		} finally {
			state.isLoadingMembers = false
		}
	},

	async getAgencyTeamGroups(): Promise<void> {
		state.isLoadingGroups = true

		try {
			const response = await getAgencyTeam(api, getAgencyId())

			state.agencyTeam = response
			state.dueDateSettings = response.agency.due_dates
			state.prebuiltTracks = response.agency.prebuilt_tracks
			state.trainingGroups = response.agency.training_groups
			state.trainingCurriculums = response.agency.training_programs.map(curriculum => ({ ...curriculum, id: curriculum.id }))
			state.slideoverCurriculums = state.trainingCurriculums
			state.slideoverPrebuiltCurriculums = state.prebuiltTracks
			state.newCurriculum.type = 'blank'

			state.selectedCurriculumId = state.trainingCurriculums[state.trainingCurriculums.length - 1].id
			state.newCurriculum.curriculum.id = `curriculum-change-${state.selectedCurriculumId}`

			const trainingCurriculum = response.agency.training_programs.find(curriculum => curriculum.is_default)

			if (trainingCurriculum && !state.selectedCurriculumId) {
				state.selectedCurriculumId = trainingCurriculum.id
			} else {
				state.selectedCurriculumId = state.selectedGroup?.training_program.id ?? state.selectedCurriculumId
			}

			CurriculumsStore.curriculumTracks = response.agency.training_programs

			GlobalStore.agencyBackOffice = {
				backOfficeIntegrationEnabled: response.agency.back_office_integration_enabled,
				backOfficeIntegrationProviderName: response.agency.back_office_integration_provider_name,
				marketingProductName: response.agency.marketing_product_name,
			}
		} catch (error: any) {
			GlobalStore.onHandleErrorMessages({ error })
		} finally {
			state.isLoadingGroups = false
		}
	},

	getGroupsUsingTrainingCurriculum(curriculumId: number): AgencyGroup[] {
		return state.trainingGroups.filter((group: AgencyGroup) => group.training_program.id === curriculumId)
	},

	async getTeamMemberDetails({ agencyId, memberId }: { agencyId: number, memberId: number }): Promise<void> {
		state.isLoadingMembers = true

		try {
			const response = await getTeamMember(api, { agencyId, memberId })

			state.selectedMemberId = memberId
			state.selectedMember = response

			if (state.selectedMemberId && state.selectedMember?.status === 'active') {
				await MembersStore.fetchMemberDetails(state.selectedMemberId)
			}
		} catch (error: any) {
			GlobalStore.onHandleErrorMessages({ error })
		} finally {
			state.isLoadingMembers = false
		}
	},

	mapIdsToMembers(ids: number[]): Member[] {
		return state.agencyTeamMembers.map(item => item).filter(member => ids.includes(member.id))
	},

	onCancelCreateCurriculum(cancelingCreateNewCurriculum?: boolean): void {
		this.resetNewCurriculum(cancelingCreateNewCurriculum)
		state.showCreateNewCurriculumSlideover = false

		if (cancelingCreateNewCurriculum) {
			state.newCurriculum.type = 'blank'
		}
	},

	onCancelSelectCurriculum(): void {
		state.newCurriculum.curriculum = {
			id: '',
			name: DEFAULT_SELECTED_CURRICULUM_NAME,
		}
		this.toggleSlideover()
	},

	onSubmitSelectedCurriculum(): void {
		const idString = `${replaceText(state.slideoverRadioButtonId, '', state.newCurriculum.curriculum.id)}`
		const id = parseInt(idString)

		state.slideoverCurriculumId = `${id}`
		state.copiedCurriculumId = `${id}`

		state.newCurriculum.curriculum = {
			id: state.newCurriculum.curriculum.id,
			name: state.slideoverCurriculums
				.find(curriculum => curriculum.id === id)?.name ?? state.newCurriculum.curriculum.name,
		}

		state.showSelectCurriculumSlideover = false
	},

	onUpdateSelectCurriculum(id: string, name: string): void {
		state.newCurriculum.curriculum = { id, name }
		state.selectedCurriculumId = parseInt(id)

		if ('course_total' in state.slideoverCurriculums[0]) {
			state.slideoverCurriculums = [
				...state.slideoverCurriculums.filter(curriculum => curriculum.id !== 0),
				{
					course_total: '',
					duration: '',
					id: parseInt(id),
					is_default: false,
					name,
				} as CurriculumTrack,
			]
		} else {
			state.slideoverCurriculums = [
				...state.slideoverCurriculums.filter(curriculum => curriculum.id !== 0),
				{
					id: parseInt(id),
					name,
				},
			]
		}
	},

	openCreateNewCurriculumSlideover(): void {
		this.resetNewCurriculum(true)
		state.newCurriculum.type = 'duplicate'
		state.showCreateNewCurriculumSlideover = true
	},

	async postAddNewTrainingGroup(
		form?: {
			newGroupName: string,
			selectedCurriculumId: number,
			selectedMemberIds: string[],
		}
	): Promise<void> {
		if (form) {
			const params = {
				agency_id: getAgencyId(),
				caregiver_ids: form.selectedMemberIds,
				name: form.newGroupName,
				source_curriculum_track_id: form.selectedCurriculumId,
			}

			try {
				let curriculumTrackId: number | null = null
				let response: ApiTrackCreateEditResponse | undefined = undefined

				response = await postTrackDuplicate(api, params)

				curriculumTrackId = response?.curriculum_track.id ?? state.selectedCurriculumId

				await postAddTrainingGroup(api, {
					agency_id: params.agency_id,
					caregiver_ids: params.caregiver_ids,
					curriculum_track_id: curriculumTrackId!,
					name: params.name,
				})

				GlobalStore.onHandleSuccessMessages({ message: `${params.name} added successfully.` })

				this.resetNewCurriculum()
			} catch (error: any) {
				GlobalStore.onHandleErrorMessages({ error })
			}

			return
		}

		const params = {
			agency_id: getAgencyId(),
			caregiver_ids: state.selectedMemberIds,
			curriculum_track_id: state.selectedCurriculumId!,
			name: state.newGroupName,
			source_curriculum_track_id: state.newCurriculum.type === 'pre-built' ?
				state.copiedCurriculumId : state.defaultCurriculum?.id ?? state.selectedCurriculumId ?? 0,
		}

		try {
			let curriculumTrackId: number | null = null
			let response: ApiTrackCreateEditResponse | undefined = undefined

			switch (state.newCurriculum.type) {
				case 'duplicate':
				case 'pre-built':
					response = await postTrackDuplicate(api, {
						...params,
						name: state.newCurriculum.name,
					})
					break
				case 'build':
					response = await postTrackCreate(api, {
						...params,
						name: state.newCurriculum.name,
					})
					break
			}

			curriculumTrackId = response?.curriculum_track.id ?? state.selectedCurriculumId

			await postAddTrainingGroup(api, {
				agency_id: params.agency_id,
				caregiver_ids: params.caregiver_ids,
				curriculum_track_id: curriculumTrackId!,
				name: params.name,
			})

			GlobalStore.onHandleSuccessMessages({ message: `${params.name} added successfully.` })

		} catch (error: any) {
			GlobalStore.onHandleErrorMessages({ error })
		} finally {
			this.resetNewCurriculum()
		}
	},

	async putUpdateAgencyTrainingGroup(group: AgencyGroup): Promise<void> {
		this.isProcessing = true

		try {
			await putUpdateTrainingGroup(api, {
				agency_id: getAgencyId(),
				curriculum_track_id: group.training_program.id,
				id: group.id,
				is_default: group.is_default,
				name: group.name,
			})
			await this.getAgencyTeamGroups()

			GlobalStore.onHandleSuccessMessages({ message: `${group.name} updated successfully.` })
		} catch (error: any) {
			GlobalStore.onHandleErrorMessages({ error })
		} finally {
			this.isProcessing = false
		}
	},

	resetNewCurriculum(keepContent?: boolean): void {
		state.copiedCurriculumId = ''

		if (!keepContent) {
			state.newGroupName = ''
			state.newCurriculum.name = ''
			state.newCurriculum.type = 'blank'

		}

		state.newCurriculum.curriculum = {
			id: '',
			name: DEFAULT_SELECTED_CURRICULUM_NAME,
		}

		state.selectedMemberIds = []

		if (state.slideoverCurriculums.length !== state.trainingCurriculums.length) {
			state.slideoverCurriculums.pop()
		}
	},

	setSlideoverValues(curriculumId?: string): void {
		if (state.newCurriculum.type === 'pre-built') {
			if (!state.prebuiltTracks.length) {
				return
			}

			state.slideoverCurriculums = state.prebuiltTracks
		} else {
			state.newCurriculum.type = 'duplicate'
			state.slideoverCurriculums = state.trainingCurriculums

			if (curriculumId) {
				const id = !!curriculumId ? curriculumId :
					`${this.defaultCurriculum?.id}` ??
					this.defaultNewCurriculum.curriculum.id
				const name = this.slideoverTrainingCurriculums.find(curriculum => curriculum.id === id)?.label ??
					this.newCurriculum?.name

				state.copiedCurriculumId = id
				state.newCurriculum.curriculum = { id: `${this.slideoverRadioButtonId}${id}`, name: `Copy of ${name}` }
			}
		}
	},

	toggleSlideover(): void {
		state.showCreateNewCurriculumSlideover = true
		state.showSelectCurriculumSlideover = false
	},
}

export default store
