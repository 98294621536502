import {
	format,
	formatISO9075,
	isValid,
	parse,
	parseISO,
	startOfDay,
} from 'date-fns'

import GlobalStore from '@/javascript/vuejs/stores/GlobalStore'

import {
	createDateErrorObject,
	createDateInfoObject,
	type DateError,
	type DateInfo,
	getSeparatorCharacterFromString,
	isStringOnlyDigits,
} from './utils'

const dateWithHyphen = 'yyyy-MM-dd'
const dateWithSlash = 'MM/dd/yyyy'
const localeLongDateFormat = 'MMMM d, yyyy h:mm aa O'

export function convertISOToString(date: Date | null): string {
	return !!date ? formatISO9075(date, { representation: 'date' }) : ''
}

export function longDateFormat(date: Date | null): string {
	return !!date ? format(new Date(`${date}`), localeLongDateFormat) : ''
}

export function standardDateFormat(date: Date | string | null): string {

	if (!date || date === '') {
		return ''
	}

	if (!isValid(new Date(date))) {
		GlobalStore.onHandleErrorMessages({ error: `Date is invalid: ${date}` })

		return ''
	}

	let formattedDate

	if (typeof date === 'string') {
		if (date === '') {
			return ''
		} else if (date.includes('/')) {
			formattedDate = format(parse(date, dateWithSlash, new Date()), dateWithSlash)
		} else if (date.includes('-') && !date.includes('T')) {
			formattedDate = format(parse(date, dateWithHyphen, new Date()), dateWithSlash)
		} else {
			formattedDate = format(parseISO(date), dateWithSlash)
		}
	} else if (typeof date === 'object') {
		try {
			formattedDate = format(date, dateWithSlash)
		} catch (error) {
			if (error instanceof RangeError) {
				console.error('Invalid time value:', error.message)
				GlobalStore.onHandleErrorMessages({ error: `Invalid time value: ${error.message}` })
			} else {
				console.error('Unexpected error:', error)
				GlobalStore.onHandleErrorMessages({ error: `Unexpected error: ${error}` })
			}
		}
	} else {
		formattedDate = format(startOfDay(new Date(date)), dateWithSlash)
	}

	return formattedDate ?? ''
}

export function standardDateParse(string: string): Date | number | null {
	return !!string ? parseISO(string) : null
}

export function processDateString({
	dateString,
	isRequired,
	minDateString,
	maxDateString,
}: ProcessDateStringOptions): DateError | DateInfo {
	// remove spaces from date string
	dateString = dateString.replace(/\s/g, '')

	// deal with empty value
	if (dateString.length === 0) {
		if (!isRequired) {
			return createDateInfoObject({ formattedDate: '' })
		} else {
			return createDateErrorObject({ errorType: 'date' })
		}
	}

	// get the first valid separator character from the date string (i.e. "/", ".", "-")
	const separatorChar = getSeparatorCharacterFromString(dateString)

	// create the parts array (will hold month, day, year)
	let partsArray: string[] = []

	// if there are no separator characters, attempt to parse the string anyways -- only if it has ONLY digits and is exactly 8 characters long
	if (!separatorChar && dateString.length === 8 && isStringOnlyDigits(dateString)) {
		partsArray = [
			dateString.substr(0, 2), dateString.substr(2, 2), dateString.substr(4, 4),
		]
	}

	// split the string on the separator character
	if (separatorChar) {
		partsArray = dateString.split(separatorChar)
	}

	// date is invalid if there aren't three parts to it (month, day, and year)
	if (partsArray.length !== 3) {
		return createDateErrorObject({ errorType: 'date' })
	}

	// date is invalid if one of its parts contains non-digit characters
	for (let i = 0; i < partsArray.length; i++) {
		const part = partsArray[i]

		if (!isStringOnlyDigits(part)) {
			if (i === 0) {
				return createDateErrorObject({ errorType: 'month' })
			}

			if (i === 1) {
				return createDateErrorObject({ errorType: 'day' })
			}

			if (i === 2) {
				return createDateErrorObject({ errorType: 'year' })
			}
		}
	}

	// date is invalid if first part (month) doesn't have EITHER 1 or 2 characters
	if (partsArray[0].length !== 1 && partsArray[0].length !== 2) {
		return createDateErrorObject({ errorType: 'month' })
	}

	// date is invalid if second part (day) doesn't have EITHER 1 or 2 characters
	if (partsArray[1].length !== 1 && partsArray[1].length !== 2) {
		return createDateErrorObject({ errorType: 'day' })
	}

	// date is invalid if third part (year) doesn't have EITHER 2 or 4 characters
	if (partsArray[2].length !== 2 && partsArray[2].length !== 4) {
		return createDateErrorObject({ errorType: 'year' })
	}

	// date is invalid if third part (year) has two characters AND -- when converted to an integer -- is less than 0 or more than 50
	// this makes it so a year of 2051 or greater cannot be typed as e.g. "1/1/51"
	let yearAsInt = parseInt(partsArray[2])

	if (partsArray[2].length === 2 && (yearAsInt < 0 || yearAsInt > 50)) {
		return createDateErrorObject({ errorType: 'year' })
	}

	// if the first part (month) has only one character, pad it with a leading 0
	if (partsArray[0].length === 1) {
		partsArray[0] = `0${partsArray[0]}`
	}

	// if the second part (day) has only one character, pad it with a leading 0
	if (partsArray[1].length === 1) {
		partsArray[1] = `0${partsArray[1]}`
	}

	// if the third part (year) has only two characters, pad it with a leading 20 (for the current millennium)
	if (partsArray[2].length === 2) {
		partsArray[2] = `20${partsArray[2]}`
	}

	// The date is invalid if the year is not between 1900-2069
	yearAsInt = parseInt(partsArray[2])
	if (parseInt(partsArray[2]) < 1900 || parseInt(partsArray[2]) > 2069) {
		return createDateErrorObject({ errorType: 'year' })
	}

	// create the final, formatted date by combining the parts & use the standard "/" separator
	const finalDate = new Date(partsArray.join('/'))
	const finalDateString = standardDateFormat(finalDate)

	if (!isValid(finalDate)) {
		return createDateErrorObject({ errorType: 'date' })
	} else {
		const minDate = minDateString && new Date(minDateString)
		const maxDate = maxDateString && new Date(maxDateString)

		const minError = minDate && finalDate < minDate
		const maxError = maxDate && finalDate > maxDate

		// date is invalid if it's below the specified min date or above the specified max date
		// return the formatted date for only a min/max error, so the date can still be auto-formatted in the input even though it's out of bounds
		if (minError) {
			return createDateErrorObject({
				errorType: 'min',
				formattedDate: finalDateString,
			})
		} else if (maxError) {
			return createDateErrorObject({
				errorType: 'max',
				formattedDate: finalDateString,
			})
		}
	}

	// return the formatted date string if there were no errors
	return createDateInfoObject({ formattedDate: finalDateString })
}

interface ProcessDateStringOptions {
	/** A string in format MM/DD/YYYY. */
	dateString: string
	isRequired: boolean
	maxDateString: string
	minDateString: string
}
